// Gallery Box
import Image from 'next/image'
import Link from 'next/link'

import { useMedia } from 'react-use'

import PrismicLink from '@components/PrismicLink'

interface Props {
	slice: any
	index: number
}

export default function GalleryBox({ slice, index }: Props) {
	const { primary } = slice

	const isDesktop = useMedia('( min-width: 1024px )', false)

	return (
		<section className={`gallery-box section ${primary.align_box_to_bottom ? 'pb-0' : ''}`}>
			{/* Box container */}
			<div
				className={`gallery-box-container ${
					slice.slice_label === 'box_right' ? 'md:justify-end' : 'md:justify-start'
				}`}
			>
				{/* Box */}
				<div
					className={`bg-white  p-12 xl:p-16 ${
						primary.offset_background_image ? 'max-w-[650px] xl:max-w-[750px]' : 'max-w-[480px]'
					}`}
				>
					{/* Kicker */}
					{primary.section_kicker?.[0] && (
						<p className="mb-2 font-medium">{primary.section_kicker[0].text}</p>
					)}

					{/* Title */}
					{primary.section_title?.[0] && (
						<h2 className="text-4xl xl:text-5xl title">
							{primary.section_title[0].text}
						</h2>
					)}

					{/* Description */}
					{/* TODO: Make richtext component */}
					{primary.section_description?.[0] && (
						<p className="subtitle">{primary.section_description[0].text}</p>
					)}

					{/* CTA */}
					{primary?.section_cta && (
						<PrismicLink
							link={primary.section_cta}
							label={primary.section_cta_label}
							className={`mt-8 button button-${primary?.section_cta_color || 'primary'}`}
						/>
					)}
				</div>
			</div>
			<div
				className={`bg-gray-200 absolute inset-0 ${
					// eslint-disable-next-line no-nested-ternary
					primary?.offset_background_image && slice.slice_label === 'box_right'
						? 'lg:right-[15%]'
						: primary.offset_background_image
						? 'lg:left-[15%]'
						: ''
				}`}
			>
				{primary.section_image?.desktop?.url && (
					<div className="relative w-full h-full max-lg:hidden">
						<Image
							layout="fill"
							objectFit="cover"
							src={primary.section_image.desktop.url}
							alt={primary.section_image.desktop.alt || ''}
							priority={isDesktop && index === 0}
						/>
					</div>
				)}
				{primary.section_image?.mobile?.url && (
					<div className="relative w-full h-full lg:hidden">
						<Image
							layout="fill"
							objectFit="cover"
							src={primary.section_image.mobile.url}
							alt={primary.section_image.desktop.alt || ''}
							priority={!isDesktop && index === 0}
						/>
					</div>
				)}
			</div>
		</section>
	)
}
